import React from "react";
import styled from "styled-components";
import LinkButton from "./LinkButton";
import Cesta from "../../assets/img/cesta2.svg";


const CoUdelameInner = styled.div`
  margin: 120px 0;

  @media (max-width: 900px) {
    margin: 60px 0;
  }
  
  h2 {
    font-family: NY-Medium;
    font-size: 32px;
    color: #000000;
    text-align: center;
    margin-bottom: 90px;

    @media (max-width: 900px) {
      margin: 0 auto;
      font-size: 25px;
      line-height: 35px;
      margin-bottom: 50px;
      width: calc(100% - 50px);
    }
  }
  
  .coUdelameInner {
    max-width: 980px;
    margin: 0 auto;
    background: url(${Cesta});
    background-position: 7%;
    background-repeat: no-repeat;
    background-size: 20%;
    width: calc(100% - 100px);

    @media (max-width: 1000px) {
      background: none;
    }

    @media (max-width: 900px) {
      width: calc(100% - 50px);
      max-width: fit-content;
    }

    .step {
      display: flex;
      max-width: 800px;
      margin-left: auto;

      @media (max-width: 1000px) {
        width: fit-content;
        margin-left: 0;
      }

      &:nth-child(2) {
        margin: 150px 0;
        margin-right: auto;

        @media (max-width: 1000px) {
          margin-left: 0;
          width: fit-content;
        }

        @media (max-width: 900px) {
          margin: 60px auto;
          margin-left: 0;
        }
      }

      @media (max-width: 1000px) {
        margin-right: auto;
      }

      @media (max-width: 900px) {
        max-width: 600px;
        display: block;
      }

      .cislo {
        font-family: NY-Medium;
        font-size: 62px;
        color: #000000;
        text-align: center;
        background: #F0F2F8;
        width: 130px;
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;

        @media (max-width: 900px) {
          margin-bottom: 40px;
          width: 70px;
          height: 70px;
          font-size: 33px;
        }
      }

      .stepInner {
        max-width: 600px;
        margin-left: 70px;

        @media (max-width: 900px) {
          margin-left: 0px;
        }

        h3 {
          font-family: Visuelt-Medium;
          font-size: 22px;
          color: #000000;
          margin-bottom: 25px;
        }

        ul {
          padding-left: 20px;

          li {
            font-family: Visuelt-Regular;
            font-size: 16px;
            color: #5A5A5A;
            margin-top: 15px;
          }
        }
      }
    }
  }

  a {
    margin: 0 auto !important;
    margin-top: 90px !important;

    @media (max-width: 900px) {
      margin-top: 60px !important;
    }
  }
`;






const CoUdelame = ({ prvni, druhy, treti }) => {
  return (
    <CoUdelameInner>
      <h2>Co vše pro vás uděláme</h2>
      <div className="coUdelameInner">
        <div className="step">
          <div className="cislo">1</div>
          <div className="stepInner">
            {prvni}
          </div>
        </div>

        <div className="step">
          <div className="cislo">2</div>
          <div className="stepInner">
            {druhy}
          </div>
        </div>

        <div className="step">
          <div className="cislo">3</div>
          <div className="stepInner">
            {treti}
          </div>
        </div>
      </div>
      <LinkButton text="Začít plánovat pohřeb" to="/nabidka/dotaznik/" />
    </CoUdelameInner>
  );
}

export default CoUdelame;


