import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";
import LinkButton from "./LinkButton";
import CallButton from "./CallButton";
import OverenaFirma from "./OverenaFirma";
import { useMediaQuery } from 'react-responsive';
import Check from "../../assets/img/peceCheck.svg";
import Ilustrace2 from "../../assets/img/ilustracePohreb2.svg";
import Ilustrace3 from "../../assets/img/ilustracePohreb3.svg";
import Jo from "../../assets/img/pricingCheck.svg";
import CoUdelame from "../../components/pohrby/CoProVasUdelame";
import RecenzePohreb from "../../components/pohrby/RecenzePohreb";
import SupportPanel from "../../components/SupportPanel";
import PoSvem from "../../components/pohrby/PoSvem";
import UvodniText from "../../components/pohrby/UvodniText";
import Poplatky from "./Poplatky";
import Pujcka from "./Pujcka";



const NaceneniInner = styled.div`
  .naceneni {
    position: relative;
    margin: 120px 0;
    
    
    @media (max-width: 899px) {
      margin: 60px 0;
    }

    .pozadi {
      position: absolute !important;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 0;
    }
    
    .naceneniInner {
      position: relative;
      z-index: 1;
      display: flex;
      max-width: 1150px;
      justify-content: space-between;
      margin: 0 auto;
      align-items: flex-start;
      width: calc(100% - 100px);

      @media (max-width: 1000px) {
        display: block;
      }

      @media (max-width: 899px) {
        width: 100%;
        display: block;
      }

      .left {
        max-width: 540px;

        @media (max-width: 1200px) {
          width: calc(100% - 500px);
        }

        @media (max-width: 1000px) {
          width: calc(100% - 0px);
        }

        @media (max-width: 899px) {
          width: calc(100% - 50px);
        }

        @media (max-width: 1200px) {
          max-width: none;
        }

        @media (max-width: 899px) {
          max-width: 600px;
          margin: 0 auto;

        }

        h2 {
          font-family: Ivar;
          font-size: 32px;
          color: #000000;
          line-height: 47px;

          @media (max-width: 899px) {
            font-size: 25px;
            line-height: 35px;
          }
        }

        p {
          font-family: Visuelt-Regular;
          font-size: 18px;
          color: #5F5F5F;
          line-height: 28px;
          margin-top: 35px;
          margin-bottom: 35px;

          @media (max-width: 899px) {
            margin-top: 30px;
            margin-bottom: 25px;
            font-size: 16px;
            line-height: 25px;
          }
        }

        ul {
          margin-top: 60px;
          padding-bottom: 35px;
          border-bottom: 2px solid #E1E1E1;
          margin-bottom: 35px;

          @media (max-width: 899px) {
            margin-top: 40px;
            padding-bottom: 30px;
            margin-bottom: 30px;
          }

          li {
            list-style: none;
            display: flex;
            align-items: center;
            margin-bottom: 25px;

            &:last-child {
              margin-bottom: 0;
            }

            img {
              margin-right: 20px;
            }

            span {
              font-family: Visuelt-Regular;
              font-size: 18px;
              color: #000000;
              line-height: 24px;

              @media (max-width: 899px) {
                font-size: 17px;
              }

              @media (max-width: 370px) {
                font-size: 16px;
              }
            }
          }
        }

        .cenaWrapper {
          margin-bottom: 50px;

          .cenaInner {
            background: #FFFFFF;
            border: 2px solid #E1E1E1;
            border-radius: 5px;
            max-width: 280px;
            margin-bottom: 25px;
            
            .zakladni {
              font-family: Visuelt-Medium;
              font-size: 16px;
              color: #000000;
              text-align: center;
              padding-top: 10px;
              padding-bottom: 10px;
              background: #E1E1E1;
            }

            .cena {
              font-family: Visuelt-Medium;
              font-size: 26px;
              color: #000000;
              text-align: center;
              padding: 20px 0;

              span {
                color: #979797;
                font-size: 16px;
              }
            }
            
          }
        }

        .usetrite {
          font-family: Visuelt-Regular;
          font-size: 16px;
          color: #265B30;
          background: #CEEECB;
          padding: 6px 15px;
          border-radius: 10px;
          display: block;
          width: fit-content;
          margin-bottom: 40px;
          line-height: 25px;
          margin-top: -25px;

          @media (max-width: 370px) {
            margin-bottom: 30px;
          }
        }
      }

      .right {
        .bublina {
          background: #FFFFFF;
          box-shadow: 0 9px 20px 0 rgba(0,0,0,0.07);
          border-radius: 5px;
          padding: 40px;
          max-width: 420px;
          
          @media (max-width: 1200px) {
            width: 380px;
          }

          @media (max-width: 899px) {
            max-width: 520px;
            margin: 0 auto;
            padding: 40px;
          }

          @media (max-width: 899px) {
            width: calc(100% - 120px);
          }

          @media (max-width: 500px) {
            width: calc(100% - 80px);
            padding: 20px;
          }
        }
        
        .doplnky {
          @media (max-width: 1000px) {
            margin-top: 50px;
          }
          
          h3 {
            font-family: Ivar;
            font-size: 26px;
            color: #000000;
            line-height: 40px;
            margin-bottom: 40px;

            @media (max-width: 899px) {
              font-size: 22px;
              line-height: 30px;
            }
          }

          .doplnek {
            margin-top: 40px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            &:first-child {
              margin-top: 0;
            }

            @media (max-width: 500px) {
              display: block;
            }
           
            .doplnekText {
              width: calc(100% - 155px - 25px);

              @media (max-width: 500px) {
                width: calc(100%);
                margin-top: 20px;
              }

              h4 {
                font-family: Visuelt-Medium;
                font-size: 18px;
                color: #000000;
                line-height: 24px;

                a {
                  color: #000000;
                  font-family: Visuelt-Medium;
                  text-decoration: none;

                  &:hover {
                    text-decoration: underline;
                  }
                }
              }

              p {
                font-family: Visuelt-Regular;
                font-size: 16px;
                color: #646464;
                line-height: 25px;
                margin: 8px 0;
              }

              span {
                font-family: Visuelt-Bold;
                font-size: 14px;
                color: #646464;
                line-height: 25px;
              }
            }

            .obrazek {
              border-radius: 10px;
            }
          }
        }

        .goodbyePece {
          margin-top: 40px;

          .top {
            display: flex;
            align-items: center;

            @media (max-width: 440px) {
              display: block; 
            }

            h3 {
              font-family: Visuelt-Medium;
              font-size: 26px;
              color: #0D1C31;
              margin-right: 20px;

              span {
                color: #E1C06E;
              }
            }

            div {
              background: #E1C06E;
              color: #fff;
              width: 130px;
              padding: 6px 0;
              text-align: center;
              font-family: Visuelt-Medium;
              border-radius: 5px;

              @media (max-width: 440px) {
                display: block;
                margin-top: 15px;
              }
            }
          }

          p {
            font-family: Visuelt-Regular;
            font-size: 16px;
            color: #5F5F5F;
            line-height: 25px;
            margin-bottom: 30px;
            margin-top: 20px;
          }

          ul {
            border-bottom: 2px solid #E1E1E1;
            padding-bottom: 30px;
            margin-bottom: 25px;

            li {
              display: flex;
              align-items: center;
              margin-top: 20px;

              img {
                margin-right: 15px;
              }

              span {
                font-family: Visuelt-Regular;
                font-size: 16px;
                color: #000000;
                line-height: 25px;
              }
            }
          }

          .bot {
            display: flex;
            align-items: center;

            @media (max-width: 440px) {
              display: block;
            }

            .price {
              font-family: Visuelt-Medium;
              font-size: 22px;
              color: #000000;
              margin-right: 15px;
            }

            .poznamka {
              font-family: Visuelt-Regular;
              font-size: 16px;
              color: #949494;

              @media (max-width: 440px) {
                display: block;
                margin-top: 13px;
              }
            }
          }
        }
      }
    }
  }
`;



const Naceneni = ({h2, p, checklist, price, feature, extras, extrasText}) => {
  const isLess1000 = useMediaQuery({
    query: '(max-width: 1000px)'
  });

  const isLess899 = useMediaQuery({
    query: '(max-width: 899px)'
  });

  return (
    <NaceneniInner>
      <div className="naceneni">
        <div className="naceneniInner">
          <div className="left">
            <h2>{h2}</h2>
            <p>{p}</p>
            <Poplatky />
            <ul>
              {checklist.map(task => {
                return (
                  <li key={task}>
                    <img src={Jo} alt="" />
                    <span>{task}</span>
                  </li>
                );
              })}
            </ul>

            <div className="cenaWrapper">
              <div className="cenaInner">
                <div className="zakladni">Základní cena od</div>
                <div className="cena">{price} <span>(vč. DPH)</span></div>
              </div>
              
              <Pujcka />
            </div>
            

            {feature &&
              <span className="usetrite">{feature}</span>
            }
            {/* <LinkButton to="/nabidka/dotaznik/" text="Začít nezávazně plánovat" /> */}
            <LinkButton to="/nabidka/dotaznik/" text="Zjistit přesnou cenu" />
          </div>

          <div className="right">
            <div className="bublina doplnky">
              {/* <h3>{extrasText}</h3> */}
              
              {extras.map(ex => {

                if (ex.h4 === "Výběr jiné rakve") {
                  return(
                    <div key={ex.h4} className="doplnek">
                      <Img className="obrazek" alt={ex.h4} fixed={ex.img} />
                      <div className="doplnekText">
                        <h4><Link to="/rakve/">{ex.h4}</Link></h4>
                        <p>{ex.p}</p>
                        <span>{ex.price}</span>
                      </div>
                    </div>
                  )
                }

                else {
                  return(
                    <div key={ex.h4} className="doplnek">
                      <Img className="obrazek" alt={ex.h4} fixed={ex.img} />
                      <div className="doplnekText">
                        <h4>{ex.h4}</h4>
                        <p>{ex.p}</p>
                        <span>{ex.price}</span>
                      </div>
                    </div>
                  )
                }
              })}
            </div>

            <div className="bublina goodbyePece">
              <div className="top">
                <h3>Goodbye péče<span>+</span></h3>
                <div>Doporučujeme</div>
              </div>
              <p>Ušetříme vám čas a starosti. Vše vyřešíme za vás. Nikam nemusíte jezdit ani s nikým nic řešit.</p>
              <ul>
                <li>
                  <img src={Check} alt="" />
                  <span>Vyřídíme veškerou dokumentaci na matrice</span>
                </li>
                <li>
                  <img src={Check} alt="" />
                  <span>Vyzvedneme u vás doma oblečení zesnulého</span>
                </li>
                <li>
                  <img src={Check} alt="" />
                  <span>Vytiskneme a osobně vám dovezeme parte</span>
                </li>
                <li>
                  <img src={Check} alt="" />
                  <span>Osobně vám doručíme urnu až domů</span>
                </li>
                <li>
                  <img src={Check} alt="" />
                  <span>Převedeme digitální účty a sociální sítě</span>
                </li>
              </ul>
              <div className="bot">
                <span className="price">+ 1 990 Kč</span>
                <span className="poznamka">K dispozici jen v Praze a Brně</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </NaceneniInner>
  );
}

export default Naceneni;


